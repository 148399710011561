module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Abmulder BV","short_name":"Abmulder","start_url":"/?pwa=true","background_color":"#fff","theme_color":"#4baa6a","display":"minimal-ui","icon":"src/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c5a154b0ee7783004387a543cbe93e46"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://88bb4eb63704493f944c70bad2ff5db6@o409936.ingest.sentry.io/6010204","tracesSampleRate":1,"release":"gatsby@2025-04-03T13:00:13.384Z","environment":"production","enabled":true,"debug":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"sm":"(min-width: 36rem)","md":"(min-width: 48rem)","lg":"(min-width: 62rem)","xl":"(min-width: 75rem)"}},
    },{
      plugin: require('../../../packages/gatsby-theme-menus/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../packages/gatsby-theme-apolloclient/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../themes/acm/gatsby-browser.js'),
      options: {"plugins":[],"core":{"sourceStrapi":{"themes":{"pagebuilder":true,"vehicles":true,"vehicleLease":true,"employees":true,"rating":true,"blog":true,"searchagent":true}}}},
    },{
      plugin: require('../../../packages/gatsby-theme-pagebuilder/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-GYBDFLLB53"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":false,"respectDNT":true,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
